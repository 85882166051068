const DEV = process.env.NODE_ENV === 'development'
import { set } from 'gsap'
import Plyr from 'plyr'
import 'plyr/dist/plyr.css'

export default {
  init() {},
  finalize() {
    const muxPlayer = document.getElementById('mainMuxPlayer')
    const playButton = document.getElementById('mainPlayButton')

    // Add click event listener to the button
    playButton.addEventListener('click', function () {
      // Play the video using the Mux Player API
      document.getElementById('playerCover').classList.add('opacity-0')
      setTimeout(() => {
        document.getElementById('playerCover').classList.add('hidden')
      }, 1000)
      muxPlayer.play()
      const videoPaywall = document.querySelector('.main-video .video-paywall')
      if (videoPaywall) {
        muxPlayer.addEventListener('timeupdate', function (event) {
          if (event.target.currentTime > 5) {
            muxPlayer.pause()
            muxPlayer.setAttribute('controls', 'false')
            //liveMuxPlayer.removeAttribute('muted')
            //event.target.setAttribute('controls', 'false')
            // const videoPaywall =
            //   event.target.parentElement.parentElement.querySelector(
            //     '.video-paywall'
            //   )
            videoPaywall.classList.remove('hidden')
          }
          // console.log('time update!', event)
          // console.log('time update!', event.target.currentTime)
        })
      }
    })

    // const player = new Plyr('#player', {
    //   hideControls: false,
    // })
    // player.on('ready', (event) => {
    //   player.toggleControls(false)
    // })
    // player.on('play', (event) => {
    //   player.toggleControls(true)
    //   document.getElementById('playerCover').classList.add('opacity-0')
    //   //console.log('play')
    // })
    // player.on('pause', (event) => {
    //   player.toggleControls(false)
    // })
    // JavaScript to be fired on all pages, after page specific JS is fired
    //console.log('finalize common');
  },
}
